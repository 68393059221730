import { Typography } from "@mui/material";
import { PlayPauseButton } from "../Media/PlayPauseButton";
import LockIcon from "@mui/icons-material/Lock";
import MediaQuery from "react-responsive";
import {KeyPriceBadge} from "../ui/KeyPriceBadge";

type RequireAll<T, TRequiredGroup> =
  | (T & TRequiredGroup)
  | (Partial<Record<keyof T, never>> & TRequiredGroup);

type Album = RequireAll<
  { id: number; playAllButton: boolean; playAllAction: () => void },
  {
    artwork?: string;
    title: string;
    info?: string;
    textColor?: string;
    variant?: "small" | "large";
    notes?: string;
    onClick?: () => void;
    hideImage?: boolean;
    locked?: boolean;
  }
>;
const url =
  "https://cdn-stg.unlocktheotherside.com/public/coming_soon_keyhole_32c2a28c4c.jpg";

export function AlbumCard({
  id,
  artwork,
  title,
  info,
  variant = "small",
  playAllButton = false,
  playAllAction,
  onClick,
  hideImage = false,
  locked = false,
}: Album) {
  return (
    <>
      {variant === "small" ? (
        <div className="flex flex-col" onClick={onClick}>
          <div className="tile-image-container">
            <img
              src={artwork ? artwork : url}
              alt={title}
              style={{ opacity: hideImage ? 0 : 1 }}
              className="absolute bottom-0 left-0 sm:min-h-[120px] lg:min-h-[300px]"
            />
            {locked && (
              <div className="absolute left-[5px] top-[5px] h-[24px] w-[24px]">
                <LockIcon />
              </div>
            )}
          </div>
          <div className="my-2 flex flex-col sm:max-w-[120px] lg:max-w-[300px]">
            <Typography variant="body1">{title}</Typography>
            <Typography variant="body2">{info}</Typography>
          </div>
        </div>
      ) : (
        <div className="w-full flex-col" onClick={onClick}>
          <img
            src={artwork ? artwork : ""}
            alt={title}
            style={{
              opacity: hideImage ? 0 : 1,
              minHeight: 120,
              width: "100%",
            }}
          />
          {locked && (
            <div className="album-lock absolute left-[5px] top-[5px] h-[24px] w-[24px]">
              <LockIcon />
            </div>
          )}
          <div className="flex w-full items-center justify-between">
            <MediaQuery minWidth={1024}>
              <div className="my-2 flex-col">
                <Typography variant="h4">{title}</Typography>
                <Typography variant="h5">{info}</Typography>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
              <div className="my-2 flex-col">
                <Typography variant="h5">{title}</Typography>
                <Typography variant="h6">{info}</Typography>
              </div>
            </MediaQuery>
            {playAllButton && playAllAction && (
              <PlayPauseButton
                locked={locked}
                id={id}
                onPlayAll={playAllAction}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AlbumCard;
