import { Route } from "react-router-dom";
import Locoqueen from "../Arcade/locoqueen";
import { ArcadeDetails } from "./Details";
import { Suspense } from "react";
import { Skeleton } from "../ui/Skeleton";
import { SkeletonVariants } from "../types/generated";

export function useArcadeRoutes() {
  return (
    <>
      <Route
        path="/arcade/:arcadeId"
        element={
          <Suspense fallback={<Skeleton variant={SkeletonVariants.event} />}>
            <ArcadeDetails />
          </Suspense>
        }
      />
      <Route path="/arcade/game-remixes/locoqueen" element={<Locoqueen />} />
    </>
  );
}
