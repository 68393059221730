import { Route } from "react-router-dom";
import { CharityDetails } from "../Charities/Details";
// import { IndexPage } from "../ui/IndexPage";

export function useCharityRoutes() {
  return (
    <>
      <Route path="/igf/:charityId" element={<CharityDetails />} />
    </>
  );
}
